<template>
  <Layout class="goodsManagment">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-form-item prop="id">
          <el-input
            v-model="formData.id"
            clearable
            placeholder="商品ID"
            @change="formData.id = formData.id.trim()"
            @keyup.enter.native="handleSearch"
          />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="formData.goodsStatus"
            placeholder="上架状态"
            clearable
          >
            <el-option
              v-for="(item, index) in goodsStatusList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleAddGoods"
        >
          添加商品
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="ID"
        prop="id"
        min-width="100px"
      />
      <el-table-column
        label="商品名"
        prop="goodsName"
        min-width="180px"
      />
      <el-table-column
        label="首页图片"
        prop="id"
        width="160px"
      >
        <template #default="{row}">
          <el-image
            v-if="row.headerImage"
            class="game-cover"
            fit="contain"
            :src="row.headerImage"
            lazy
          />
          <span v-else>Not Found</span>
        </template>
      </el-table-column>
      <el-table-column
        label="商品标价"
        prop="goodsPrice"
        min-width="160px"
      />
      <el-table-column
        label="上架状态"
        prop="goodsStatus"
        min-width="160px"
      >
        <template #default="{row}">
          {{ (goodsStatusMap[row.goodsStatus] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="260px"
      >
        <template #default="{row}">
          <el-button
            type="primary"
            plain
            @click="handleEditRow(row.id)"
          >
            <i class="el-icon-edit" />
          </el-button>

          <el-button
            :type="row.goodsStatus === 2 ? 'success' : 'info'"
            :loading="row.loading"
            plain
            @click="handleToggleRow(row)"
          >
            <i
              class="fa"
              :class="{
                'fa-check-circle': row.goodsStatus === 2,
                'fa-ban': row.goodsStatus === 1
              }"
            />
          </el-button>

          <el-button
            :loading="row.loading"
            @click="handleDeleteRow(row)"
          >
            <i class="el-icon-delete" />
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryDataList"
    />
  </Layout>
</template>

<script>
import {
  goodsStatusList,
  goodsStatusMap
} from '@/utils/selectOptions.js'
import {
  getMallv2PhysicalGoodsList,
  updateMallv2PhysicalGoodsStatus,
  deleteMallv2PhysicalGoodsStatus
} from '@/api/electronicEntity.js'
export default {
  data () {
    return {
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      goodsStatusList,
      goodsStatusMap,
      listInfo: {
        list: [],
        total: 0,
        loading: false
      }
    }
  },
  created () {
    this.handleSearch()
  },
  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.queryDataList()
    },
    queryDataList () {
      const formData = this.formData
      this.listInfo.loading = true
      getMallv2PhysicalGoodsList(formData)
        .then(res => {
          if (res.code === 200) {
            this.listInfo.list = res.data.list || []
            this.listInfo.total = res.data.total || 0
          }
        })
        .finally(() => {
          this.listInfo.loading = false
        })
    },
    handleAddGoods () {
      this.$router.push({
        name: 'goods-item-create'
      })
    },
    handleEditRow (id) {
      this.$router.push({
        name: 'goods-item-edit',
        query: {
          id
        }
      })
    },
    handleToggleRow (row) {
      const nextStatus = row.goodsStatus === 1 ? 2 : 1
      this.$set(row, 'loading', true)
      updateMallv2PhysicalGoodsStatus({
        id: row.id,
        goodsStatus: nextStatus
      })
        .then(res => {
          if (res.code === 200) {
            this.queryDataList()
          }
        })
        .finally(() => {
          this.$set(row, 'loading', false)
        })
    },
    handleDeleteRow (row) {
      this.$confirm('此操作将删除商品, 是否继续', '提示', { type: 'warning ' })
        .then(() => {
          this.$set(row, 'loading', true)
          deleteMallv2PhysicalGoodsStatus({ id: row.id })
            .then(res => {
              if (res.code === 200) {
                this.queryDataList()
              }
            })
            .finally(() => {
              this.$set(row, 'loading', false)
            })
          this.queryDataList()
        })
    }
  }
}
</script>

<style lang="less">
 .game-cover{
    width: 96px;
    height: 50px;
    border-radius: 4px;
  }
</style>
